<template>
  <div class="pos-dialog-container">
    <el-dialog
      title="小票详情"
      :visible.sync="show"
      @open="openDialog"
      :before-close="closeDialog"
      center
      :close-on-click-modal="false"
      width="30%"
    >
      <el-form ref="form" :model="form" label-width="80px" id="posDemo">
        <!-- 订单信息 -->
        <el-row v-if="isShow">
          <el-col :span="24" class="center">
            <div class="venueName">{{ posData.venueName || "" }}</div>
          </el-col>
          <el-col :span="24" class="center">
            <div class="orderTitle">
              <span>******</span><span class="orderTitleName">订单详情</span
              ><span>******</span>
            </div>
          </el-col>
          <el-col :span="24">
            <el-form-item label="订单号" prop="orderNo">
              <div>{{ posData.orderNo || "" }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="下单时间" prop="orderTime">
              <div>{{ posData.orderTime }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="客户姓名" prop="customerName">
              <div>{{ posData.customerName || "" }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="手机号" prop="customerPhone">
              <div>{{ posData.customerPhone || "" }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="支付方式" prop="payTypeDesc">
              <div>{{ posData.payTypeDesc || "" }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="支付金额" prop="actualPayPrice">
              <div>{{ "¥  " + posData.actualPayPrice || "" }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="收银员" prop="cashier">
              <div>{{ posData.cashier || "" }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 消费明细 -->
        <el-row v-if="isShow">
          <el-col :span="24" class="center">
            <div class="orderTitle">
              <span>******</span><span class="orderTitleName">消费明细</span
              ><span>******</span>
            </div>
          </el-col>
          <el-col :span="24">
            <el-form-item label="预约日期" prop="orderNo">
              <div>
                {{
                  (posData &&
                    posData.orderDetailPrintInfoDTOS &&
                    posData.orderDetailPrintInfoDTOS[0] &&
                    posData.orderDetailPrintInfoDTOS[0].date) ||
                  ""
                }}
              </div>
            </el-form-item>
          </el-col>
          <!-- 具体场地 -->
          <div
            v-if="
              posData &&
              posData.orderDetailPrintInfoDTOS &&
              posData.orderDetailPrintInfoDTOS.length > 0
            "
          >
            <div
              v-for="(item, index) in posData.orderDetailPrintInfoDTOS"
              :key="index"
            >
              <el-col :span="24">
                <el-form-item label="预约场地">
                  <div>
                    <span>{{ item.sportsProjectName || "" }}</span>
                    <span style="margin-left: 20px">{{
                      item.spaceName || ""
                    }}</span>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="预约时间">
                  <div>{{ item.timeSlot || "" }}</div>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="场地费用">
                  <div>{{ "¥  " + item.price || "" }}</div>
                </el-form-item>
              </el-col>
              <el-col :span="24" v-if="item.liveBroadcast">
                <el-form-item label="直播服务">
                  <div>{{ "¥  " + item.cameraPrice || "" }}</div>
                </el-form-item>
              </el-col>
            </div>
          </div>
          <el-col :span="24">
            <el-form-item label="优惠价格">
              <!-- <div class="start">
                <span class="mr-20">优惠价格</span>
                <span>{{"¥  " + posData.memberCardDiscountAmount || ""}}</span>
              </div> -->
              <div>{{ "¥  " + posData.memberCardDiscountAmount || "" }}</div>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="合计">
              <!-- <div class="start">
                <span class="mr-20">合计</span>
                <span>{{"¥  " + posData.sumPrice || ""}}</span>
              </div> -->
              <div>{{ "¥  " + posData.sumPrice || "" }}</div>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 预订信息 -->
        <template
          v-if="
            posData &&
            posData.orderDetailPrintInfoDTOS &&
            posData.orderDetailPrintInfoDTOS.length > 0
          "
        >
          <el-row
            v-for="(item, index) in posData.orderDetailPrintInfoDTOS"
            :key="index"
          >
            <el-col :span="24" class="center">
              <div class="orderTitle">
                <span>******</span><span class="orderTitleName">预订信息</span
                ><span>******</span>
              </div>
            </el-col>
            <el-col :span="24">
              <el-form-item label="预约日期" prop="orderNo">
                <div>{{ item.date || "" }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="预约场地">
                <div>
                  <span>{{ item.sportsProjectName || "" }}</span>
                  <span style="margin-left: 20px">{{
                    item.spaceName || ""
                  }}</span>
                </div>
                <div v-if="item.liveBroadcast">
                  <span>视频直播服务</span>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="预约时间">
                <div>{{ item.timeSlot || "" }}</div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <div class="middle">
                <img
                  :src="item.qrCode"
                  alt=""
                  srcset=""
                  class="order-code-img"
                />
              </div>
            </el-col>
            <el-col :span="24">
              <el-form-item label="验证码">
                <div>{{ item.ticketNo }}</div>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </el-form>
      <template slot="footer">
        <el-button class="footer-confirm" @click="handlePrint">打印</el-button>
        <el-button class="footer-cancel" @click="closeDialog">{{
          $t("cancel")
        }}</el-button>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import mixin from "@/mixins/dialogMixin";
import apis from "@/apis";

export default {
  mixins: [mixin],
  props: {
    show: {
      required: true,
      type: Boolean,
    },
    Language: {
      required: false,
      type: Number,
      default: 0,
    },
    isShow: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        pageNum: 1,
        total: 0,
        pageSize: 10,
      }, // 实例化一个表单的变量
      rules: "", // 实例化一个表单的规则
      data: {},
      posData: {},
    };
  },
  computed: {
    ...mapState(["DROPDOWNBOX", "venueId"]),
  },
  methods: {
    /**
     * @function 初始化弹窗
     */
    init() {
      // this.$refs.form.resetFields()
      console.log("我初始化了");
    },
    // 打印-bodyHTML-替换
    /* eslint-disable */
    // handlePrint() {
    //   const bodyHTML = window.document.body.innerHTML;
    //   //把获取的 局部div内容赋给body标签, 相当于重置了 body里的内容
    //   window.document.body.innerHTML = document.getElementById("posDemo").innerHTML;
    //   //调用打印功能
    //   window.print();
    //   //重新给页面内容赋值；
    //   window.document.body.innerHTML = bodyHTML;
    //   // location.reload()
    // },
    /**
     * @function 回显的时候获取数据
     * @param {Object} data 要传输给后端的数据
     */
    getDataQuery(orderId) {
      if (this.isShow) {
        this.$http
          .get(`${apis.getPrintInfo}?orderId=${orderId}`)
          .then((res) => {
            if (res.data.code === 0) {
              this.posData = res.data.data;
            }
          });
      } else {
        this.$http
          .get(`${apis.getSharingResultPrintInfo}?sharingResultId=${orderId}`)
          .then((res) => {
            if (res.data.code === 0) {
              this.posData = res.data.data;
            }
          });
      }
    },
    // 打印-iframe
    handlePrint() {
      /* eslint-disable */
      var el = document.getElementById("posDemo");
      var iframe = document.createElement("IFRAME");
      var doc = null;
      iframe.setAttribute("id", "posDemo");
      iframe.setAttribute(
        "style",
        "position:absolute;width:0px;height:0px;left:-500px;top:-500px;"
      );
      document.body.appendChild(iframe);
      doc = iframe.contentWindow.document;
      //这里可以自定义样式
      doc.write("<LINK rel='stylesheet' type='text/css' href='pos.css'>");
      doc.write("<div>" + el.innerHTML + "</div>");
      doc.close();
      iframe.contentWindow.focus();
      this.closeDialog();
      //iframe样式加载较慢,延时0.5s调用打印
      setTimeout(() => {
        iframe.contentWindow.print();
        document.body.removeChild(iframe);
      }, 500);
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../../../assets/styles/mixin.less";
.mr-20 {
  margin-right: 20px;
}
.venueName {
  font-size: 20px;
  font-weight: bold;
}
.orderTitle {
  padding: 10px;
  margin-top: 10px;
  .orderTitleName {
    padding: 0 20px;
  }
}
.order-code-img {
  width: 180px;
  height: 180px;
}
.el-form-item {
  margin-bottom: 0;
}

.table-container {
  margin-top: 20px;
  width: 100%;
}
.middle {
  text-align: center;
}
.cusomer-head-wrapper {
  display: flex;
  align-items: center;
  .head-item {
    text-align: center;
    flex: 1;
    font-size: 16px;
    font-weight: 400;
  }
  .date {
    flex: 2;
  }
}
.customer-item {
  display: flex;
  align-items: center;
  border: 1px solid #ebeef5;
  border-bottom: 0;
  padding: 16px 20px;
  .customer-item-detial {
    flex: 1;
    text-align: center;
  }
}
.title {
  border: 1px solid #dcdfe6;
  padding: 16px;
  border-radius: 10px;
  div {
    display: inline-block;
    margin-right: 10px;
    width: 18%;
  }
}
.table-title {
  border: 1px solid #dcdfe6;
  padding: 16px 20px;
  div {
    display: inline-block;
    margin-right: 10px;
    width: 15%;
    text-align: center;
  }
}
.start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.around {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
